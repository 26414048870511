<template>
  <el-tooltip
      :content="content"
      :disabled="labelTooltip.disabled ? labelTooltip.disabled : false"
      :effect="labelTooltip.effect ? labelTooltip.effect : 'dark'"
      :enterable="labelTooltip.enterable ? labelTooltip.enterable : true"
      :hide-after="labelTooltip.hideAfter ? labelTooltip.hideAfter : 0"
      :offset="labelTooltip.offset ? labelTooltip.offset : 0"
      :open-delay="labelTooltip.openDelay ? labelTooltip.openDelay : 0"
      :placement="labelTooltip.placement ? labelTooltip.placement : 'bottom'"
      :tabindex="labelTooltip.tabindex ? labelTooltip.tabindex : 0"
      :transition="labelTooltip.transition ? labelTooltip.transition : 'el-fade-in-linear'"
      :visible-arrow="labelTooltip.visibleArrow ? labelTooltip.visibleArrow : true"
      v-if="labelTooltip"
  >
    <span class="labelWrap">{{ content }}</span>
  </el-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: ['labelTooltip', 'content'],
};
</script>

<style scoped>

</style>
